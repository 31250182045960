.filter-ads{
    &__wrapper{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        // background:pink;
        width: 100%;
    }
    &__search-btn{
        width: 100%;
    }
    &__group{
        text-align: left;
    }
    &__input-group{
        text-align: left;   
    }
    &__column{

    }
    &__row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        > div{
            flex: 1;
            margin-right: 10px;
        }
        > div:last-child{
            margin-right:0;
        }
    }
    &__buttons{
        display: flex;
        column-gap: 10px;
        flex-direction: column;
        margin-top: 20px;
        .btn{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
        }
    }
  
}

@media screen and (min-width: 1000px)  {
    .filter-ads{
        &__wrapper{
            display: flex;
            column-gap: 10px;
            > * {
                flex: 2;
            }
        }
        &__column{
            display: flex;
            flex-direction: column;
            &:last-of-type{
                flex: 1;
                justify-content: flex-end;
            }
        }
        &__buttons{
            width:100%;
            margin-bottom: 15px;
        }
       
    }
}