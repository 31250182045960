.pagination{
    margin-top: 10px;
    &__wrapper{
        display: flex;
        justify-content: center;
     
    }
    .page-item{
        margin-right: 5px;
    }
}