$content-width: 450px;

// font 
.racing-sans {
  font-family: "Racing Sans One", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  padding: 10px;
}

a{
  color: black;
  text-decoration: none;
}

.btn{
  max-width: 100%;
  min-height: 30px;
  display: block;
  padding: 15px;
  margin-bottom: 5px;
}


.logo-wrapper{
  margin-bottom:20px;
  margin-top:20px;
  h1{
    color:black;
  }
}

img{
  max-width:100%;
}

.app-header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  width: 100%;
  max-width: $content-width;

  &__btn{
    margin-right: 30px;
    cursor: pointer;
  }
  &__center{
    display: flex;
    justify-content: center;
  }
}



.App-link {
  color: #61dafb;
}

.main-content-wrapper{
  min-height: 70vh;
  display: flex;
  justify-content: center;
  max-width: $content-width;
}


@media screen and (min-width: 1000px) {

  .app-header, .main-content-wrapper{
    max-width: 90%;
  }

  .filter-ads__wrapper{
    // max-width: 900px;
  }

  .cars-list__description{
    margin-left: auto;
    margin-right: auto;
  }

}

@media screen and (min-width: 1500px) {

  .app-header, .main-content-wrapper{
    max-width: 1380px;
  }

  .filter-ads__wrapper{
    max-width: 1380px;
  }

}


