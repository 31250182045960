.crawl-jobs{
    &__wrapper{
        // background:pink;
        max-width: 600px;
        width: 100%;
        // background: pink;
    }
    &__element{
        border: 1px solid gray;
        margin-bottom: 1px;
        margin-bottom: 10px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        width: 100%;
    }
    &__element-main-info{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__element-url{
        margin-right: auto;
        // background: pink;
        text-align: left;
    }
}