.loading-wrapper{
    width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: center;
    min-height: 20vh;
}

.loading-image{
    width: 100px;
    height: 100px;
    fill: rgb(82, 82, 82);
    animation: rotate 2s linear infinite;
}


@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }