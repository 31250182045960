.app-footer{
    &__wrapper{
        width: 100%;
        max-width: 100vw;
        background: rgb(235, 235, 235);
        padding: 10px 50px;
        box-sizing: border-box;
        text-align: center;
    }
}

.app-menu{
    &__wrapper{
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    &__main{
        display: flex;
        flex-direction: row;
    }
    &__item{
        margin-right: 10px;
        font-size: 18px;
    }
}