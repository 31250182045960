.single-ad{
    
    &__wrapper{
        display: flex;
        margin-bottom: 20px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size:18px;
        max-width: 1200px;
        width: 90%;
        box-sizing: border-box;

    }

    &__display-wrapper{
        text-align: left;
        width: 100%;
        margin-bottom: 20px;
    }

    &__source-link{
        margin-bottom: 20px;
        display: block;
    }

    &__images-wrapper{
        max-width: 90vw;
        margin-bottom: 15px;
    }

    &__back-button{
        text-decoration: underline;
    }
    &__location{
        display: flex;
        align-items: center;
        flex-direction: center;
        svg{
            margin-right: 10px;
        }
    }

}


@media  screen and (min-width: 500px) {
    .single-ad{
        &__images-wrapper{
            max-width: 1000px;
            width: 90vw;
        }
    }
}