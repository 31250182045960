
.cars-list{
    &__description{
        text-align:left;
        width: 100%;
        p{
            max-width: 650px;
            font-size: 18px;
        }
        
    }
    &__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        text-align: center;
    }
    &__inner-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        >div {
            flex-grow: 1;
            flex-basis: 0;
        }
    }
    &__single-ad{
        border: 1px solid rgb(198, 196, 196);
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        font-size: 18px;
        justify-content: space-between;
        position: relative;
        width: 100%;
        &.pint-ad{
            img{
                width: 100%;
            }
        }
        .date{
            font-size: 14px;
            color: gray;

        }
        &.hidden{
            opacity: .8;
        }

    }
    &__admin-details{
        display: flex;
        justify-content: flex-start;
        column-gap: 10px;
        font-size: 20px;
        margin-bottom: 20px;
    }

}

.car-ad{
    &__price{
        color: rgb(46, 46, 46);
        font-size:14px;
        font-weight: 700;
    }
    &__wrapper{
        position: relative;
        .dropdown{
            display: block;
            position: absolute;
            z-index: 2;
            right: 0;
        }
    }
    &__hidden-mark{
        background:gray;
        display: block;
        position: absolute;
        left: 0;
        top:0 ;
        z-index: 2;
        padding: 5px 10px;
    }
    &__image{
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 65%;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-color: lightgray

    }
    &__title{
        font-size: 15px;
        line-break: anywhere;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
    }
    
    &__details{
        text-align: left;
        padding: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        background-color: rgb(242, 242, 242);
    }
    &__details-lower{
        display:flex;
        justify-content: space-between;
    }
    &__details-row{
        display: flex;
        font-size: 14px;
        column-gap: 10px;
        > div{
            display: flex;
            align-items: center;
           svg{
            margin-right: 5px;
           } 
        }
    }
    &__source{
        background: rgb(236, 236, 236);
        display: flex;
        align-items: center;
        gap: 5px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        // width: 100%;
        font-size: 12px;
        padding: 5px;
    }
}

@media screen and (min-width: 500px) {
    .cars-list{
        &__inner-wrapper{
            flex-direction: row;
            flex-wrap: wrap;
        }
        &__single-ad{
            width: 450px;
        }
    }
}

@media screen and (min-width: 1000px) {
    .cars-list{
        &__single-ad{
            width: auto;
        }
        &__inner-wrapper{
        
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            justify-content: normal ;
            max-width: 100%;
      
        }
    }
}

@media screen and (min-width: 1300px) {
    .cars-list{

        &__inner-wrapper{ 
            grid-template-columns: repeat(3, minmax(0, 1fr));
            justify-content: normal ;
            max-width: 100%;
      
        }
    }
}